<script>
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import { Doughnut, mixins } from 'vue-chartjs'

export default {
  components: {
    ChartJSPluginDatalabels
  },
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: Object,
    options: Object
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
